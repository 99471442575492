<template>
  <div class="Promt">
    <Loader text="Loading Promt" v-if="!promt" />
    <div>
      <header class="Promt__header">
        <div
          class="Promt__header-container d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <p class="Promt__header-title">
              <router-link
                to="/prompts?selectedTab=0"
                tag="span"
                class="Promt__header-title-link"
              >
                Prompts
              </router-link>
              <v-icon small class="Promt__header-title-icon">
                mdi-chevron-right
              </v-icon>
              <span class="Promt__header-title-name" v-if="promt.name">
                {{ promt.name }}
              </span>
              <span class="Promt__header-title-no-name" v-else>
                Prompt name here
              </span>
            </p>
          </div>

          <div class="d-flex justify-space-between">
            <v-btn
              color="success"
              :disabled="this.runningPromt || editPromtLoading"
              @click="testRun"
              :loading="this.runningPromt"
              elevation="0"
              outlined
            >
              <v-icon class="mr-3" right>mdi-bug-check</v-icon> Test Prompt
            </v-btn>
            <div class="Promt__header-button-divider"></div>
            <v-btn
              color="primary"
              elevation="0"
              :disabled="editPromtLoading"
              @click="save"
              :loading="editPromtLoading"
            >
              Save Prompt
            </v-btn>
          </div>
        </div>
      </header>

      <div class="Promt__main-content">
        <v-row>
          <v-col cols="12" lg="8">
            <div>
              <mustache-template
                label="Prompt Template"
                class="TextArea"
                :value="textValue"
                @input="(val) => onUpdate(val, `settings.promptTemplate`)"
                :suggestions="availableInputFields"
                :singleLine="false"
              />
            </div>
            <v-textarea
                class="TextArea"
                outlined
                :disabled="true"
                dense
                :label="'Output'"
                :placeholder="'Output'"
                hide-details="auto"
                novalidate
                :rows="5"
                v-model="this.output.result"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card :width="370" class="SideSettings" :elevation="1">
              <PromtSettings />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';
import { getModel } from '@/util/actionsModels';
import Loader from '@/components/loader/loader.vue';
import PromtSettings from './promt-settings/promt-settings.vue';
// import { relativeTimeThreshold } from 'moment';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: sharedActions } = createNamespacedHelpers('shared');
const {
  mapGetters: promtsGetters,
  mapActions: promtsActions,
  mapMutations: promtMutations,
} = createNamespacedHelpers('promts');
const { mapActions: credentialsActions, mapGetters: credentialsGetters } =
  createNamespacedHelpers('credentials');
const { mapActions: workflowDetailsActions } =
  createNamespacedHelpers('workflows/details');

const { mapActions: AIChatActions, mapGetters: AIChatGetters } =
    createNamespacedHelpers('AIChat');

export default {
  name: 'PromtDetails',
  components: {
    PromtSettings,
    Loader,
    MustacheTemplate,
  },
  data() {
    return {
      checkStatusInterval: undefined,
      textValue: '',
      placeholder: 'Your prompt here...',
      crumbs: [
        {
          text: 'Prompts',
          disabled: false,
          exact: true,
          to: { name: 'Prompts' },
        },
        {
          text: 'Prompt',
          disabled: true,
        },
      ],
      checkStatusData: {
        action: {},
        task: {}
      }
    };
  },
  computed: {
    ...promtsGetters({
      promt: 'PROMT',
      output: 'OUTPUT',
      runningPromt: 'RUNNING_PROMT',
      fetchPromtLoading: 'FETCH_PROMT_LOADING',
      editPromtLoading: 'EDIT_PROMT_LOADING',
      settings: 'PROMT_SETTINGS',
      inputs: 'PROMT_INPUTS',
      attachments: 'PROMPT_ATTACHMENTS',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS'
    }),
    authenticationItem() {
      return this.authentications.find(
        (item) => item.id === this.settings.authenticationId
      );
    },
    availableInputFields() {
      return [...this.inputs, ...this.attachments];
    },
  },
  async mounted() {
    this.toggleHeader(false);
    this.fetchAuthentications();
    this.setInputs([]);
    this.setAttachments([]);
    const { promptId } = this.$route.params;
    if (promptId !== 'new') {
      await this.getSystemPrompts()
      await this.fetchPromt(promptId);
      this.textValue = this.promt.text;
    }
  },
  methods: {
    onUpdate(value, path) {
      this.textValue = value;
      this.$emit('update', value, path);
    },
    ...sharedActions(['toggleHeader']),
    ...AIChatActions(['getSystemPrompts']),
    ...promtMutations({
      setInputs: 'SET_INPUTS',
      setPromt: 'SET_PROMT',
      setRunningPromt: 'SET_RUNNING_PROMT',
      setOutput: 'SET_OUTPUT',
      setPromtLogs: 'SET_PROMT_LOGS',
      setAttachments: 'SET_ATTACHMENTS',
    }),
    ...promtsActions(['fetchPromt', 'updatePromt', 'clearPromt']),
    ...credentialsActions(['fetchAuthentications']),
    ...workflowDetailsActions(['executeSingleAction','getWorkflowTask']),
    editPromt(prop, value) {
      const promt = cloneDeep(this.promt);
      lodashSet(promt, prop, value);
      this.setPromt(promt);
    },
    async testRun() {
      this.setRunningPromt(true);
      this.setOutput({ model: {}, action: {} });
      this.setPromtLogs([]);
      this.settings.promptTemplate = this.textValue;
      this.settings.promptInputs =
        this.inputs.map((field) => ({
          ...field,
          value: `Object.${field.name}`,
        })) || [];
      this.settings.promptAttachments =
          this.attachments.map((field) => ({
            ...field,
            value: `Object.${field.name}`,
          })) || [];
      this.settings.tools = this.promt.tools;
      const inputs = {};
      this.availableInputFields.forEach((input) => {
        inputs[input.name] = input.value;
      });
      this.settings.systemPrompt = this.systemPrompts.find(( sprompt ) => sprompt.id === this.promt.systemPromptId)?.prompt
      const options = {
        creds: this.settings.authenticationId,
        data: { ...this.settings, automation: this.textValue },
        action: 'gen_ai_prompt',
        input: inputs,
        isServerless: true,
      };
      const action = { input: inputs };
      try {
        const task = await this.executeSingleAction(options);
        this.checkStatusData = { task, action }
        this.checkStatusInterval = setInterval(() => { this.checkTaskStatus() },1000)
      } catch (error) {
        this.setOutput({ action });
        this.setPromtLogs(error.PromtLogs);
        this.setRunningPromt(false);
      }
    },
    async checkTaskStatus() {
      const { task, action } = this.checkStatusData
      const data = await this.getWorkflowTask({ storageId: null, taskId: task.id });
      if(!data || data.status === 'PENDING') {
        this.setRunningPromt(true);
        return;
      }
      const model = getModel(data.model);
      action.output = data.output;
      this.setOutput({ model, action, taskId: data.taskId });
      this.setPromtLogs(data.PromtLogs);
      clearInterval(this.checkStatusInterval);
      this.setRunningPromt(false);
    },
    save() {
      const updatedPromt = {
        ...this.promt,
        text: this.textValue,
      };
      return this.updatePromt(updatedPromt);
    },
  },
  beforeDestroy() {
    this.toggleHeader(true);
  },
};
</script>

<style lang="scss">
@import './promt';
</style>
